<template>
  <main id="app" :class="'route' + currentRoute?.toString()">
    <CookiePopup ref="cookiePopup" @enableAnalytics="enableAnalytics" />
    <template v-if="outdatedBrowser">
      <OutdatedBrowserOverlay />
    </template>
    <template v-else>
      <LanguageSelector v-if="!status.loggedIn && showLanguageSelector" :class="{ centered: true }" />
      <Header v-if="loadSecondary && status.loggedIn && options.header" />
      <AccountNavigation v-if="status.loggedIn && searchQuery === '' && options.header && options.nav_account" />
      <ExternalNotification />
      <Notification :notifications="notification" @onTransitionStart="popNotification" source="notification-module" />
      <aside id="notification-portal-header"></aside>
      <router-view v-if="status.loggedIn || options.public" :key="reloads" v-slot="{ Component }">
        <component v-if="Component" :is="Component" />
        <BendersLoader v-else :hidden="false" :delay="500" style="height: 400px" size="large" />
        <template v-if="Component">
          <ContactInformation v-if="status.loggedIn && options.info" />
          <Footer v-if="status.loggedIn && options.footer" @openCookiePopup="openCookiePopup" />
        </template>
      </router-view>
      <DebugInspector />
      <div
        v-if="environment != null"
        class="environment-notice is-uppercase tracking-wide is-size-7 has-text-white"
        :class="'environment-' + (environment || '').toLowerCase()"
      >
        {{ environment }}
      </div>
    </template>
  </main>
</template>

<script lang="ts">
  import Header from './views/partials/Header.vue';
  import Footer from './views/partials/Footer.vue';
  import AccountNavigation from './views/partials/AccountNavigation.vue';
  import ContactInformation from './views/partials/ContactInformation.vue';
  import CookiePopup from './views/partials/CookiePopup.vue';
  import Notification from '@/components/Notification.vue';
  import ExternalNotification from '@/components/ExternalNotification.vue';
  import OutdatedBrowserOverlay from '@/components/OutdatedBrowserOverlay.vue';
  import DebugInspector from '@/components/debug-inspector/DebugInspector.vue';
  import LanguageSelector from '@/components/LanguageSelector.vue';
  import BendersLoader from '@/components/BendersLoader.vue';
  import { mapState, mapActions } from 'pinia';
  import { LiveSession } from '@/tracking';
  import * as Sentry from '@sentry/browser';
  import { event, bootstrap } from 'vue-gtag';

  import { useAccountStore } from '@/stores/account';
  import { useNotificationStore } from '@/stores/notification';
  import { useHistoryStore } from '@/stores/history';
  import { useShortcutStore } from '@/stores/shortcut';

  export default {
    data() {
      return {
        searchQuery: '',
        loadSecondary: false,
        analytics: false,
      };
    },
    components: {
      Header,
      Footer,
      AccountNavigation,
      ContactInformation,
      CookiePopup,
      Notification,
      ExternalNotification,
      OutdatedBrowserOverlay,
      DebugInspector,
      LanguageSelector,
      BendersLoader,
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user']),
      ...mapState(useNotificationStore, ['notification']),
      ...mapState(useHistoryStore, ['reloads']),
      options() {
        return {
          nav_account: this.$route && this.$route.meta.nav_account === true,
          header: this.$route && this.$route.meta.header !== false,
          footer: this.$route && this.$route.meta.footer !== false,
          info: this.$route && this.$route.meta.info !== false,
          public: this.$route && this.$route.meta.public === true,
        };
      },
      currentUser() {
        if (!this.user || !this.user?.user) return {};

        return {
          name: (this.user?.user.firstName + ' ' + this.user?.user.lastName || '').trim(),
          email: this.user?.user.id || '',
        };
      },
      outdatedBrowser() {
        // @ts-ignore
        let isIE = /*@cc_on!@*/ false || !!document.documentMode;
        return isIE;
      },
      currentRoute() {
        return this.$route?.name;
      },
      environment() {
        if (import.meta.env.VITE_APP_ENV === 'staging') return 'Stage';
        if (import.meta.env.VITE_APP_ENV === 'testing') return 'Test';
        if (import.meta.env.VITE_APP_ENV === 'development') return 'Dev';
        return null;
      },
      showLanguageSelector() {
        return this.currentRoute !== 'Wordpress Page';
      },
    },
    watch: {
      user: {
        immediate: true,
        handler() {
          this.setUserTracking();
        },
      },
      '$route.query.locale': {
        handler() {
          if (this.$route.query.locale) {
            this.setLanguage(this.$route.query.locale.toString());
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(useNotificationStore, ['popNotification']),
      ...mapActions(useAccountStore, ['setLanguage']),
      ...mapActions(useShortcutStore, ['initShortcuts', 'destroyShortcuts']),
      enableAnalytics() {
        // gTag
        bootstrap();

        // LiveSession
        const lsOptions = {
          rootHostname: '',
        };
        if (import.meta.env.NODE_ENV === 'production') {
          lsOptions.rootHostname = '.benders.se';
        } else if (
          import.meta.env.NODE_ENV === 'staging' ||
          import.meta.env.NODE_ENV === 'testing' ||
          import.meta.env.NODE_ENV === 'testing2'
        ) {
          lsOptions.rootHostname = '.benders-k8s.duvadev.se';
        }
        LiveSession.init('3fcf25c4.6b5c69d4', lsOptions, {
          devMode: import.meta.env.VITE_APP_ENV === 'development',
          scriptURL: 'https://cdn.livesession.io/track.js',
        });
        LiveSession.newPageView();

        this.analytics = true;
        this.setUserTracking();
      },
      setUserTracking() {
        let userInformation = {
          name: 'Utloggad',
          email: 'anonymous',
          params: {} as any,
        };
        if (this.user && this.user?.user) {
          userInformation = {
            name: `${this.user?.user.firstName} ${this.user?.user.lastName}`,
            email: this.user?.user.id,
            params: {
              acting_as_user: this.user?.actingAsUser ? 'yes' : 'no',
              benders_employee: this.user?.isBendersEmployee ? 'yes' : 'no',
            },
          };
        }
        if (userInformation.params.acting_as_user === 'yes') {
          // Don't notify sentry/livesession/google with the "acting as" user info
          // Let them remember the previous user info that is most likley the admin
          return;
        }
        // Track to all the services
        // OpenReplay.setUserID(userInformation.email);
        if (this.analytics) {
          LiveSession.identify(userInformation);
          event('set', { user_id: userInformation.email });
        }
        Sentry.setUser({
          ...userInformation.params,
          id: userInformation.email,
          email: userInformation.email,
          username: userInformation.name,
        });
      },
      openCookiePopup() {
        (this.$refs?.cookiePopup as typeof CookiePopup).openCookiePopup();
      },
    },
    mounted() {
      this.initShortcuts([
        { type: 'alt+c', handler: () => this.$router.push('/checkout') }
      ]);
      this.$nextTick(() => {
        this.loadSecondary = true;
      });
    },
    unmounted() {
      this.destroyShortcuts();
    }
  };
</script>

<style>
  .routeLogin {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .hero {
    flex-grow: 1;
  }

  /* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
