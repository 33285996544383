<template>
  <div
    id="cookie-banner"
    ref="banner"
    :class="['cookie-banner', !showCookieConsent ? 'cookie-banner--hidden' : null]"
    style=""
  >
    <div class="cookie-banner__inner">
      <div class="cookie-banner__info">
        <p>
          <i18n-t keypath="cookie_notification.text">
            <router-link class="privacy-policy-link" :to="'/wp-page/' + divisionLinks.privacyPolicy">
              {{ $t('generic.privacy_policy') }}
            </router-link>
          </i18n-t>
        </p>
      </div>

      <div class="cookie-banner__actions">
        <button class="cookie-banner__button" @click.prevent="consent">
          {{ $t('cookie_notification.consent_confirmation') }}
        </button>

        <button class="cookie-banner__button" @click.prevent="refuse">
          {{ $t('cookie_notification.consent_rejection') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { mapState } from 'pinia';
  import Cookie from 'js-cookie';
  import { useTranslationStore } from '@/stores/translation';
  import { I18nT } from 'vue-i18n';

  export default {
    name: 'CookiePopup',
    data() {
      return {
        showCookieConsent: !['yes', 'no'].includes(Cookie.get('cookie_consent') ?? ''),

        previousChoice: undefined as string | undefined,
      };
    },
    methods: {
      consent() {
        Cookie.set('cookie_consent', 'yes', {
          expires: 28,
        });
        this.showCookieConsent = false;

        if (this.previousChoice == 'yes') return;

        this.$nextTick(() => this.$emit('enable-analytics'));
      },
      refuse() {
        Cookie.set('cookie_consent', 'no', {
          expires: 28,
        });
        this.showCookieConsent = false;

        this.deleteCookies();

        // Can't disable analytics once enabled, reload the page
        if (this.previousChoice == 'yes') {
          this.$nextTick(() => location.reload());
        }
      },
      openCookiePopup() {
        // Re-open the cookie popup, depending on the previous choice we might need to reload the page
        this.previousChoice = Cookie.get('cookie_consent');
        Cookie.remove('cookie_consent');
        this.showCookieConsent = true;
      },
      deleteCookies() {
        // Remove all cookies except benders_logged_in and cookie_consent
        Object.keys(Cookie.get()).forEach((key) => {
          if (!['benders_logged_in', 'cookie_consent'].includes(key)) {
            Cookie.remove(key);
            Cookie.remove(key, { domain: '.benders.se' });
          }
        });
      },
    },
    computed: {
      ...mapState(useTranslationStore, ['divisionLinks']),
    },
    mounted() {
      if (Cookie.get('cookie_consent') === 'yes') this.$emit('enable-analytics');

      if (Cookie.get('cookie_consent') === 'no') this.deleteCookies();
    },
  };
</script>

<style>
  .cookie-banner {
    position: fixed;
    z-index: 1;
    width: 25rem;
    bottom: 50px;
    right: 20px;
    background-color: #a6192e;
    color: #fff;
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
  }
  .cookie-banner__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    flex-direction: column;
    -ms-flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 20px 20px;
  }
  .cookie-banner__info p,
  .cookie-banner__info p a {
    margin: 0;
    color: white;
  }
  .cookie-banner__info p a {
    font-weight: bold;
  }
  .cookie-banner__actions {
    margin-top: 20px;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
  .cookie-banner__button {
    display: inline-block;
    width: auto;
    overflow: visible;
    padding: 8px 8px;
    border: 2px solid #fff;
    background: 0 0;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    height: auto;
  }
  .cookie-banner--hidden {
    opacity: 0;
    transform: translateY(150%);
    pointer-events: none;
  }
  .cookie-banner__button:hover,
  .cookie-banner__button:active {
    border-color: #fff;
    background: #fff;
    color: #58595b;
  }
  @media only screen and (max-width: 1260px) {
    .cookie-banner__inner {
      width: auto;
      margin: auto;
      padding: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
    .cookie-banner__inner {
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .cookie-banner__info {
      width: auto;
      margin-bottom: 20px;
    }
    .cookie-banner__actions {
      padding-left: 0;
      text-align: center;
    }
  }
  @media only screen and (max-width: 500px) {
    .cookie-banner {
      width: auto;
      right: 0;
      left: 0;
      bottom: 0;
      padding-bottom: 25px;
    }
    .cookie-banner__inner {
      width: auto;
    }
  }
</style>
