import dayjs from '@/helpers/dayjs';
import i18n from '@/languages/i18n';
import { useAccountStore } from '@/stores/account';

// General Product functions
export function recursiveGet(item, ...path) {
  if (!item) return null;

  for (let i = 0; i < path.length; i++) {
    if (item[path[i]]) {
      item = item[path[i]];
    } else {
      return null;
    }
  }
  return item;
}

const currencyFormatter = function (number) {
  if (typeof number === 'string' && /[0-9]+,[0-9]*/.test(number)) number = number.replace(',', '.');
  const currency = new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 2 });
  let val = currency.format(number);
  val = val.replace(/(,[0-9])$/, '$10'); // Pad to two zeros
  return val.replace('−', '-'); // Replace big minus with normal dash
};

const weightFormatter = function (number) {
  const weight = new Intl.NumberFormat('sv-SE', {
    unit: 'kilogram',
    maximumFractionDigits: 2,
  });
  let val = weight.format(number);
  val = val.replace(/(,[0-9])$/, '$10'); // Pad to two zeros
  return val.replace('−', '-'); // Replace big minus with normal dash
};

const amountFormatter = function (number) {
  const amount = new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 2 });
  let val = amount.format(number);
  val = val.replace(/(,[0-9])$/, '$10'); // Pad to two zeros
  return val.replace('−', '-'); // Replace big minus with normal dash
};

export function parseJsonStream(r) {
  let rows = typeof r.data === 'string' ? r.data : JSON.stringify(r.data);
  return [...rows.split('\n').filter((data) => data !== '')].map((data) => JSON.parse(data));
}

export function formatPrice(
  prices,
  type = 'netPrice',
  amount: number | string = 1,
  options: {
    unit?: string;
    asObject?: boolean;
    offereras?: boolean;
    multiplied?: boolean;
    withoutSuffix?: boolean;
    skipRounding?: boolean;
  } = {}
) {
  if (type === null) type = 'netPrice'; // ensure 'null' sets a default value
  if (typeof amount === 'string') amount = parseFloat(amount.replace(',', '.'));
  if (typeof amount !== 'number' || isNaN(amount)) amount = 1;

  if (!prices || prices.length === 0) {
    if (options.asObject) return {};
    return options?.offereras ? i18n.global.t('price.price_offered') : '0';
  }
  let itemPrice, formatted;
  prices = prices?.[type] ?? prices; // If prices is an object, use the type as key
  for (let i = 0; i < prices.length; i++) {
    let price = prices[i];
    if (
      price.blanketAgreementNumber === '' &&
      price.priceList !== '' &&
      amount >= price['lowestQuantityLimitBasicU_M']
    ) {
      switch (type) {
        // BEN-1475: Remove 'BS' requirement for salesPrice
        case 'salesPrice':
          if (price.currency && (!itemPrice || price.salesPrice < itemPrice.salesPrice)) itemPrice = price;
          break;
        default:
          if (price.currency && (!itemPrice || price.netPrice < itemPrice.netPrice)) itemPrice = price;
          break;
      }
    }
  }
  if (!itemPrice && options.asObject) return {};
  if (!itemPrice) return ' ';

  const unit = options.unit || itemPrice.salesPriceUnitOfMeasure; // If no unit is provided, use the unit from the price (It's not always accurate, but it's better than nothing)

  let roundedAmount = options.skipRounding ? amount : roundCurrency(amount, unit);
  formatted = currencyFormatter(itemPrice[type] * (options.multiplied ? roundedAmount : 1));

  if (options.multiplied) itemPrice.multiplied = itemPrice[type] * roundedAmount;
  if (options.withoutSuffix) return formatted;
  return options.asObject
    ? itemPrice
    : `${formatted} ${itemPrice.currency}/${convertUnit(unit, { type: 'short', lowercase: true })}`;
}

let unitConversion = {
  locale: '' as any,
  short: {} as any,
  full: {} as any,
  human: {} as any,
};
export function convertUnit(unit, { type = 'long', lowercase = false }: { type?: 'long' | 'short' | 'human', lowercase?: boolean} = {}) {
  if (i18n.global.locale.value != unitConversion.locale) {
    unitConversion.locale = i18n.global.locale.value;
    unitConversion.short = {
      ark: i18n.global.t('unit.short.ark'),
      b: i18n.global.t('unit.short.b'),
      bnt: i18n.global.t('unit.short.bnt'),
      fl: i18n.global.t('unit.short.fl'),
      flm: i18n.global.t('unit.short.flm'),
      g: i18n.global.t('unit.short.g'),
      kg: i18n.global.t('unit.short.kg'),
      kkg: i18n.global.t('unit.short.kkg'),
      krt: i18n.global.t('unit.short.krt'),
      l: i18n.global.t('unit.short.l'),
      lpm: i18n.global.t('unit.short.lpm'),
      m: i18n.global.t('unit.short.m'),
      m2: i18n.global.t('unit.short.m2'),
      m3: i18n.global.t('unit.short.m3'),
      ml: i18n.global.t('unit.short.ml'),
      mm: i18n.global.t('unit.short.mm'),
      p: i18n.global.t('unit.short.p'),
      pal: i18n.global.t('unit.short.pal'),
      p2: i18n.global.t('unit.short.p2'),
      par: i18n.global.t('unit.short.par'),
      pkk: i18n.global.t('unit.short.pkk'),
      pkt: i18n.global.t('unit.short.pkt'),
      pås: i18n.global.t('unit.short.pas'),
      rul: i18n.global.t('unit.short.rul'),
      set: i18n.global.t('unit.short.set'),
      st: i18n.global.t('unit.short.st'),
      säc: i18n.global.t('unit.short.sac'),
      t: i18n.global.t('unit.short.t'),
      ton: i18n.global.t('unit.short.ton'),
      tub: i18n.global.t('unit.short.tub'),
    };
    unitConversion.full = {
      ark: i18n.global.t('unit.full.ark'),
      b: i18n.global.t('unit.full.b'),
      bnt: i18n.global.t('unit.full.bnt'),
      fl: i18n.global.t('unit.full.fl'),
      flm: i18n.global.t('unit.full.flm'),
      g: i18n.global.t('unit.full.g'),
      kg: i18n.global.t('unit.full.kg'),
      kkg: i18n.global.t('unit.full.kkg'),
      krt: i18n.global.t('unit.full.krt'),
      l: i18n.global.t('unit.full.l'),
      lpm: i18n.global.t('unit.full.lpm'),
      m: i18n.global.t('unit.full.m'),
      m2: i18n.global.t('unit.full.m2'),
      m3: i18n.global.t('unit.full.m3'),
      ml: i18n.global.t('unit.full.ml'),
      mm: i18n.global.t('unit.full.mm'),
      p: i18n.global.t('unit.full.p'),
      pal: i18n.global.t('unit.full.pal'),
      p2: i18n.global.t('unit.full.p2'),
      par: i18n.global.t('unit.full.par'),
      pkk: i18n.global.t('unit.full.pkk'),
      pkt: i18n.global.t('unit.full.pkt'),
      pås: i18n.global.t('unit.full.pas'),
      rul: i18n.global.t('unit.full.rul'),
      set: i18n.global.t('unit.full.set'),
      st: i18n.global.t('unit.full.st'),
      säc: i18n.global.t('unit.full.sac'),
      t: i18n.global.t('unit.full.t'),
      ton: i18n.global.t('unit.full.ton'),
      tub: i18n.global.t('unit.full.tub'),
    };
    unitConversion.human = {
      ark: i18n.global.t('unit.full.ark'),
      b: i18n.global.t('unit.full.b'),
      bnt: i18n.global.t('unit.full.bnt'),
      fl: i18n.global.t('unit.full.fl'),
      flm: i18n.global.t('unit.full.flm'),
      g: i18n.global.t('unit.short.g'),
      kg: i18n.global.t('unit.short.kg'),
      kkg: i18n.global.t('unit.short.kkg'),
      krt: i18n.global.t('unit.full.krt'),
      l: i18n.global.t('unit.short.l'),
      lpm: i18n.global.t('unit.full.lpm'),
      m: i18n.global.t('unit.short.m'),
      m2: i18n.global.t('unit.short.m2'),
      m3: i18n.global.t('unit.short.m3'),
      ml: i18n.global.t('unit.short.ml'),
      mm: i18n.global.t('unit.short.mm'),
      p: i18n.global.t('unit.full.p'),
      pal: i18n.global.t('unit.full.pal'),
      p2: i18n.global.t('unit.full.p2'),
      par: i18n.global.t('unit.full.par'),
      pkk: i18n.global.t('unit.full.pkk'),
      pkt: i18n.global.t('unit.full.pkt'),
      pås: i18n.global.t('unit.full.pas'),
      rul: i18n.global.t('unit.full.rul'),
      set: i18n.global.t('unit.full.set'),
      st: i18n.global.t('unit.short.st'),
      säc: i18n.global.t('unit.full.sac'),
      t: i18n.global.t('unit.full.t'),
      ton: i18n.global.t('unit.full.ton'),
      tub: i18n.global.t('unit.full.tub'),
    };
  }
  let value = unitConversion[type === 'long' ? 'full' : type][unit];
  if (!value) {
    console.error(`Could not find translation for ${unit} unit`)
  }
  value = value || unit || '';
  // BEN-1214: Convert certain units to a more readable format without changing to uppercase
  return lowercase ? value.toLowerCase() : value;
}

// Return base unit, or if not found, return first unit
export function getDefaultUnit(article) {
  if (!article?.unit) return null;

  if (article.sibling && !article.onlySoldAsPallet) {
    return article?.ArtNr.slice(-2) == '.5' ? 'p2' : 'p';
  } else if (article.onlySoldAsPallet && 'p' in article.unit) {
    return 'p';
  } else if (article.defaultUnit in article.unit) {
    return article.defaultUnit;
  } else if (article.basicUnitOfMeasure in article.unit) {
    return article.basicUnitOfMeasure;
  }

  return Object.keys(article.unit)[0];
}

export function roundCurrency(amount, unit, method = 'ceil') {
  const roundToInt = {
    ark: true,
    fl: true,
    flm: false,
    kg: false,
    krt: true,
    l: false,
    lpm: false,
    m: false,
    m2: false,
    m3: false,
    par: true,
    pkt: true,
    pås: true,
    rul: true,
    set: true,
    st: true,
    säc: true,
    t: false,
    tub: true,
  };

  if (roundToInt[unit]) {
    return Math[method](amount);
  } else {
    return Math[method](amount * 100) / 100;
  }
}

export function roundUnit(amount, decimalPlaces, asString = true) {
  let val = amount;
  if (decimalPlaces === 0) {
    val = Math.round(amount);
  } else if (!Number.isInteger(amount)) {
    val = amount.toFixed(decimalPlaces);
  }

  return asString ? (val + '').replace(/\./g, ',') : parseFloat(val);
}

const getM2EquivPiecesFunc = function (article, baseAmount = 1) {
  if (!article) return 0;
  let m2EquivPieces = article?.unfilteredUnit['m2'];
  let equivVal = baseAmount / m2EquivPieces;
  // BEN-1213: M2 equivalent values are only shown with either 2 decimals or as integers
  let nDecPlacesM2 = Number.isInteger(equivVal) ? 0 : 2;
  return m2EquivPieces ? equivVal.toFixed(nDecPlacesM2).toString().replace('.', ',') : null;
};

export function getM2EquivPieces(article, baseAmount = 1) {
  return getM2EquivPiecesFunc(article, baseAmount);
}

export function getPieces(row, article) {
  const pieces = {
    baseunit: null as string | null,
    m2: null as string | null,
  };
  if (row.unit && row.unit !== article.basicUnitOfMeasure) {
    pieces.baseunit = row?.baseunitAmount?.formatted
      ? `${row.amount == 1 ? 'à' : ''} ${row.baseunitAmount.formatted} ${article.basicUnitOfMeasure}`
      : null;
  }

  let showM2Pieces = false;
  let units = Object.keys(article.unit);
  // BEN-1445
  if (
    article.category !== 'tak' &&
    ['p', 'p2', 'pkt'].includes(row.unit) &&
    article.basicUnitOfMeasure !== 'm2' &&
    article.alternativeUnits.some((u) => u.alternateType == 1 && u.alternate == 'm2')
  ) {
    showM2Pieces = true;
    // BEN-1225
  } else if (['p', 'p2'].includes(row.unit) && ['st', 'p', 'm2'].every((u) => units?.includes(u))) {
    showM2Pieces = true;
    // BEN-1286: Show m2 equiv. pieces for articles only sold as pallets
  } else if (
    article.onlySoldAsPallet &&
    article.basicUnitOfMeasure == 'st' &&
    article.alternativeUnits.some((u) => u.alternateType == 1 && u.alternate == 'm2')
  ) {
    showM2Pieces = true;
  }

  if (!row.baseunitAmount) {
    row.baseunitAmount = getArticleBaseUnitAmount(article, row);
  }
  if (showM2Pieces && row?.baseunitAmount) pieces.m2 = `(${getM2EquivPieces(article, row.baseunitAmount.value)} m2)`;

  return pieces;
}

export function formatCurrency(number) {
  return currencyFormatter(number);
}

export function formatWeight(number) {
  return weightFormatter(number);
}

export function formatAmount(number) {
  return amountFormatter(number);
}

function isValidWarehouseStatus(status) {
  return ['20', '30', '40', '50'].includes(status);
}
export function validWarehouseStatus(status) {
  return isValidWarehouseStatus(status);
}

export function filterWarehouses(articles, warehouses, region = '') {
  if (region === '') {
    region = useAccountStore().region || 'SE';
  }

  // BEN-1659: Remove division logic
  // let divisions = {
  //   DE: ['KRI'],
  //   FI: ['FIN', 'EDS'],
  //   NO: ['LAS', 'MYS', 'SAN', 'EDS'],
  //   SE: ['BOK', 'BYG', 'EDS', 'GRA', 'ISO', 'KEB', 'VMO'],
  //   KRI: ['KRI'],
  //   FIN: ['FIN', 'EDS'],
  //   SAN: ['LAS', 'MYS', 'SAN', 'EDS'],
  //   EDS: ['BOK', 'BYG', 'EDS', 'GRA', 'ISO', 'KEB', 'VMO'],
  // };
  // let division = divisions[region];

  // if (!division) {
  //   console.error('No division found for language', region);
  //   return [{}, null];
  // }

  let defaultWarehouse;
  let warehouseIndex = warehouses.reduce((obj, warehouse) => {
    obj[warehouse.id] = warehouse;
    return obj;
  }, {});

  let filtered = Object.values(articles).reduce((obj: any, article: any) => {
    if (!article?.itemWarehouses) return obj;
    for (let i = 0; i < article.itemWarehouses.length; i++) {
      let warehouse = article.itemWarehouses[i];
      let wpWarehouse = warehouseIndex[warehouse.warehouse];
      let articleAssortments = article.categories;

      if (!wpWarehouse) {
        // console.info("No warehouse info found in wordpress for:", warehouse.warehouse)
        continue;
      }
      // BEN-1659: Remove division logic
      // if (!isValidWarehouseStatus(warehouse.status) || !division.includes(warehouse.division)) {
      if (!isValidWarehouseStatus(warehouse.status)) {
        // console.info("Status or division is invalid:", warehouse.status, warehouse.division)
        continue;
      }
      let categoryValid = wpWarehouse.categories.some((category) =>
        articleAssortments.includes(category?.toLowerCase?.())
      );
      if (!categoryValid) {
        // console.info("Article category does not match warehouses:", article.category, wpWarehouse.categories)
        continue;
      }

      // BEN-1470: Disable WEBER specific rules
      // // BEN-1395: Remove WEBER specific warehouses if not WEBER user
      // if (wpWarehouse.itemAssortment == 'WEBER' && (!store.getters['account/isWeberUser'] || !article.itemAssortments?.some((a) => a.assortment === 'WEBER'))) {
      //     continue
      // }

      if (!defaultWarehouse && (wpWarehouse.default || wpWarehouse?.defaultFor?.includes(article.category))) {
        defaultWarehouse = warehouse.warehouse;
      }
      // if(!defaultWarehouse && wpWarehouse.default) ;
      wpWarehouse.source = wpWarehouse.source || [];
      if (!wpWarehouse.source.includes(article.id)) wpWarehouse.source.push(article.id);
      obj[article.itemWarehouses[i].warehouse] = wpWarehouse;
    }
    return obj;
  }, {});

  return [filtered, defaultWarehouse];
}

export function calcFreightEstimatedDeliveryTimes(warehouse, zipCode = '', countDateFrom = null) {
  if (!warehouse || !warehouse.pickuptimes) {
    // console.err13742or("Warehouse parameter missing or invalid!")
    return {
      LKK: null,
      K17: null,
      K24: null,
      K30: null,
      LLK: null,
      '1SP': null,
      LOK: null,
      LOT: null,
    };
  }

  const pickuptimes = [...warehouse.pickuptimes];

  if (pickuptimes[0]?.date !== warehouse.nextOpenDay) pickuptimes.unshift({ date: warehouse.nextOpenDay });

  // Add pickuptimes offset to start counting from `countDateFrom`
  let dateOffset = 0;
  if (countDateFrom)
    while (pickuptimes[dateOffset]?.date && !dayjs(pickuptimes[dateOffset]?.date).isAfter(countDateFrom)) dateOffset++;

  let dates = {
    '3d': dayjs(pickuptimes[2 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[2 + dateOffset] ? 5 + dateOffset : 0)
      .format('YYYY-MM-DD'),
    '4d': dayjs(pickuptimes[3 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[3 + dateOffset] ? 6 + dateOffset : 0)
      .format('YYYY-MM-DD'),
    '6d': dayjs(pickuptimes[5 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[5 + dateOffset] ? 7 + dateOffset : 0)
      .format('YYYY-MM-DD'),
    '7d': dayjs(pickuptimes[6 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[5 + dateOffset] ? 8 + dateOffset : 0)
      .format('YYYY-MM-DD'),
    '9d': dayjs(pickuptimes[8 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[8 + dateOffset] ? 9 + dateOffset : 0)
      .format('YYYY-MM-DD'),
    '10d': dayjs(pickuptimes[9 + dateOffset]?.date ?? undefined)
      .add(!pickuptimes[9 + dateOffset] ? 10 + dateOffset : 0)
      .format('YYYY-MM-DD'),
  };

  const isBeforeEleven = dayjs().isBefore(dayjs().set('hour', 11).set('minute', 0).set('second', 0));
  const zipRegion = +(zipCode.match(/^[0-9]{2}/)?.[0] ?? 0);
  const { region } = useAccountStore();
  /*if (region == 'EDS' && zipRegion == 62) {
    return {
      LKK: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      K17: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      K24: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      K30: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['9d'] : dates['10d'],
      },
    };
  } else
  */
  if (region == 'EDS' && ((zipRegion >= 82 && zipRegion <= 99) || zipRegion == 62)) {
    return {
      LKK: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K17: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K24: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K30: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
    };
  } else {
    const sanOverride = {
      LOK: { date: dates['4d'] },
      LOE: { date: dates['4d'] },
    };
    return {
      LKK: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K17: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K24: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      K30: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['6d'] : dates['7d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['3d'] : dates['4d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['3d'] : dates['4d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['3d'] : dates['4d'],
      },
      ...(region === 'SAN' ? sanOverride : {}),
    };
  }
}

export function calcDeliveryPopupEstimatedDeliveryTimes(warehouse, zipCode = '', countDateFrom = undefined) {
  if (!warehouse || !warehouse.pickuptimes) {
    // console.error("Warehouse parameter missing or invalid!")
    return {
      LKK: null,
      K17: null,
      K24: null,
      K30: null,
      LLK: null,
      '1SP': null,
      LOK: null,
      LOT: null,
    };
  }

  const pickuptimes = [...warehouse.pickuptimes];

  if (pickuptimes[0]?.date !== warehouse.nextOpenDay) pickuptimes.unshift({ date: warehouse.nextOpenDay });

  // Add pickuptimes offset to start counting from `countDateFrom`
  let dateOffset = 0;
  if (countDateFrom)
    while (pickuptimes[dateOffset]?.date && !dayjs(pickuptimes[dateOffset]?.date).isAfter(countDateFrom)) dateOffset++;

  // Convert number of workdays to absolute number of days, startDay: Monday=0, Tuesday=1, etc
  let workDays = (n, startDay) => Math.floor((n + startDay) / 5) * 7 + ((n + startDay) % 5) - startDay;
  let dates = {
    '1d': dayjs(pickuptimes[0 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[0 + dateOffset] ? workDays(1, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '2d': dayjs(pickuptimes[1 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[1 + dateOffset] ? workDays(2, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '3d': dayjs(pickuptimes[2 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[2 + dateOffset] ? workDays(3, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '4d': dayjs(pickuptimes[3 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[3 + dateOffset] ? workDays(4, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '5d': dayjs(pickuptimes[4 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[4 + dateOffset] ? workDays(5, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '6d': dayjs(pickuptimes[5 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[5 + dateOffset] ? workDays(6, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '7d': dayjs(pickuptimes[6 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[5 + dateOffset] ? workDays(7, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '8d': dayjs(pickuptimes[7 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[6 + dateOffset] ? workDays(8, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '9d': dayjs(pickuptimes[8 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[8 + dateOffset] ? workDays(9, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
    '10d': dayjs(pickuptimes[9 + dateOffset]?.date ?? countDateFrom)
      .add(!pickuptimes[9 + dateOffset] ? workDays(10, dayjs(countDateFrom).isoWeekday() - 1) : 0, 'days')
      .format('YYYY-MM-DD'),
  };

  const isBeforeEleven = dayjs().isBefore(dayjs().set('hour', 11).set('minute', 0).set('second', 0));
  const zipRegion = +(zipCode.match(/^[0-9]{2}/)?.[0] ?? 0);
  const { region } = useAccountStore();
  /*if (region == 'EDS' && zipRegion == 62) {
    return {
      LKK: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      K17: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      K24: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      K30: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['7d'] : dates['8d'],
      },
    };
  } else
  */
  if (region == 'EDS' && ((zipRegion >= 82 && zipRegion <= 99) || zipRegion == 62)) {
    return {
      LKK: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K17: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K24: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K30: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
    };
  } else {
    const sanOverride = {
      LOK: { date: isBeforeEleven ? dates['3d'] : dates['4d'] },
      LOT: { date: isBeforeEleven ? dates['3d'] : dates['4d'] },
      LKK: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      LLK: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      K17: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      K24: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      K30: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      NOS: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      NOL: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      NOD: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      NOE: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
      NOP: { date: isBeforeEleven ? dates['6d'] : dates['7d'] },
    };
    return {
      LKK: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K17: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K24: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      K30: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      LLK: {
        date: isBeforeEleven ? dates['4d'] : dates['5d'],
      },
      '1SP': {
        date: isBeforeEleven ? dates['1d'] : dates['2d'],
      },
      LOK: {
        date: isBeforeEleven ? dates['1d'] : dates['2d'],
      },
      LOT: {
        date: isBeforeEleven ? dates['1d'] : dates['2d'],
      },
      ...(region == 'SAN' ? sanOverride : {}),
    };
  }
}

// Gets if an article has been discontinued and what message to display, if any
export function articleDiscontinued(
  { article = null, warehouse, discontinuedStatus = null }: { article?: any; warehouse: any; discontinuedStatus?: any },
  context: 'single' | 'search' | 'cart' | 'checkout' | 'cquote' | 'list' = 'search'
): { discontinued: boolean; message: string } {
  const defaultResp = {
    discontinued: false,
    message: '',
  };

  if ((!article && !discontinuedStatus) || !warehouse) return defaultResp;

  discontinuedStatus = discontinuedStatus || article?.discontinuedStatus;
  const replacementItem = discontinuedStatus?.replacementItem ?? null;

  let text = {
    single: {
      article: i18n.global.t('product.discontinued.single.article'),
      warehouse: i18n.global.t('product.discontinued.single.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.single.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.single.warehouse_replace', { article: replacementItem }),
      interchangeability: i18n.global.t('product.discontinued.single.interchangeability', { article: replacementItem }),
    },
    search: {
      article: i18n.global.t('product.discontinued.search.article'),
      warehouse: i18n.global.t('product.discontinued.search.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.search.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.search.warehouse_replace', { article: replacementItem }),
      interchangeability: i18n.global.t('product.discontinued.search.interchangeability', { article: replacementItem }),
    },
    cart: {
      article: i18n.global.t('product.discontinued.cart.article'),
      warehouse: i18n.global.t('product.discontinued.cart.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.cart.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.cart.warehouse_replace', { article: replacementItem }),
      interchangeability: i18n.global.t('product.discontinued.cart.interchangeability', { article: replacementItem }),
    },
    checkout: {
      article: i18n.global.t('product.discontinued.checkout.article'),
      warehouse: i18n.global.t('product.discontinued.checkout.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.checkout.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.checkout.warehouse_replace', {
        article: replacementItem,
      }),
      interchangeability: i18n.global.t('product.discontinued.checkout.interchangeability', {
        article: replacementItem,
      }),
      'user.replace': i18n.global.t('product.discontinued.checkout.user_replace', {
        article: replacementItem,
      }),
    },
    cquote: {
      article: i18n.global.t('product.discontinued.cquote.article'),
      warehouse: i18n.global.t('product.discontinued.cquote.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.cquote.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.cquote.warehouse_replace', { article: replacementItem }),
      interchangeability: i18n.global.t('product.discontinued.cquote.interchangeability', { article: replacementItem }),
    },
    list: {
      article: i18n.global.t('product.discontinued.list.article'),
      warehouse: i18n.global.t('product.discontinued.list.warehouse'),
      'article.replace': i18n.global.t('product.discontinued.list.article_replace', { article: replacementItem }),
      'warehouse.replace': i18n.global.t('product.discontinued.list.warehouse_replace', { article: replacementItem }),
      interchangeability: i18n.global.t('product.discontinued.list.interchangeability', { article: replacementItem }),
    },
  };

  if (!(context in text)) return defaultResp;

  const isCheckout = context === 'checkout';
  const isBendersEmployee = useAccountStore().user?.isBendersEmployee;

  let message = '';
  if (discontinuedStatus?.article) {
    if (!isBendersEmployee && !isCheckout && replacementItem) {
      message = '';
    } else if (!isBendersEmployee && isCheckout && replacementItem) {
      message = text[context]['user.replace'];
    } else if (discontinuedStatus?.interchangeability) {
      message = text[context]['interchangeability'];
    } else {
      message = replacementItem ? text[context]['article.replace'] : text[context]['article'];
    }
  } else if (discontinuedStatus?.warehouses?.includes(warehouse)) {
    if (!isBendersEmployee && !isCheckout && replacementItem) {
      message = '';
    } else if (!isBendersEmployee && isCheckout && replacementItem) {
      message = text[context]['user.replace'];
    } else {
      message = replacementItem ? text[context]['warehouse.replace'] : text[context]['warehouse'];
    }
  } else {
    return defaultResp;
  }

  return {
    discontinued: true,
    message: message,
  };
}

// Do this during sync instead?
export function isHalfPalletException(artnr) {
  console.warn('isHalfPalletException is deprecated. This is done during sync now.');
  return ['020051.5', '020055.5'].includes(artnr);
}

export function getCountryCodes() {
  return {
    AU: 'Australien',
    BE: 'Belgien',
    BG: 'Bulgarien',
    CY: 'Cypern',
    DK: 'Danmark',
    EE: 'Estland',
    FI: 'Finland',
    FR: 'Frankrike',
    FO: 'Färöarna',
    GR: 'Grekland',
    HK: 'Hongkong',
    IN: 'Indien',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Irland',
    IS: 'Island',
    IL: 'Israel',
    IT: 'Italien',
    JP: 'Japan',
    CA: 'Kanada',
    CN: 'Kina',
    XK: 'Kosovo',
    HR: 'Kroatien',
    LV: 'Lettland',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MY: 'Malaysia',
    MT: 'Malta',
    MX: 'Mexiko',
    ME: 'Montenegro',
    NL: 'Nederländerna',
    NG: 'Nigeria',
    MK: 'Nordmakedonien',
    NO: 'Norge',
    PS: 'Palestina',
    PL: 'Polen',
    PT: 'Portugal',
    QA: 'Qatar',
    RO: 'Rumänien',
    RU: 'Ryssland',
    CH: 'Schweiz',
    SK: 'Slovakien',
    SI: 'Slovenien',
    ES: 'Spanien',
    GB: 'Storbritannien',
    SE: 'Sverige',
    TW: 'Taiwan',
    TH: 'Thailand',
    CZ: 'Tjeckien',
    TR: 'Turkiet',
    DE: 'Tyskland',
    US: 'USA',
    UA: 'Ukraina',
    HU: 'Ungern',
    AX: 'Åland',
    AT: 'Österrike',
  };
}

export function canPurchase(article) {
  if (!article?.categories) return false;
  if (articleBlocked1299(article)) return false;
  const { allowedPurchase } = useAccountStore();
  return article.categories.some((category) => allowedPurchase?.[category?.toLowerCase?.()]);
}

export function canViewStock(article) {
  if (!article?.categories) return false;
  if (articleBlocked1299(article)) return false;
  const { allowedPurchase } = useAccountStore();
  if (article.categories.some((category) => allowedPurchase?.[category?.toLowerCase?.()])) {
    return true;
  }

  // BEN-1470: Disable WEBER specific rules
  // // BEN-1395: Special case for WEBER
  // if (store.getters['account/isWeberUser'] &&
  //     article.itemAssortments?.some((a) => a.assortment === 'WEBER')) {
  //     return true
  // }

  return false;
}

export function isRented(article) {
  return (
    article?.family === 'Uthyrning' ||
    article?.discountGroupItem === 'K3' ||
    article?.family === 'VA Uthyrning' ||
    article?.discountGroupItem === 'QT'
  );
}

// BEN-1299: Prevent certain articles from being purchased
export function articleBlocked1299(article) {
  const { isBendersEmployee } = useAccountStore();
  if (isBendersEmployee) return false;
  if (article?.itemAssortments?.some((ass) => ass.assortment === 'KPSTOP')) return true;
  return false;
}

export function contactSupport(article, price?: any) {
  if (articleBlocked1299(article)) return true;

  if (article?.needsQuote || price?.needsQuote) return true;
  price = price || article?.price;
  if (!price || price.salesPrice === undefined) return true;
  return price.salesPrice === 0;
}

export function isConfigurable(article) {
  return article?.configurationCode === 1;
}

export function isBatchnumber(article) {
  return ['GN', 'GP'].includes(article?.discountGroupItem);
}

export function articleNumberOfDecimalPlaces(article, selectedUnit) {
  if (selectedUnit === 'p2') selectedUnit = 'p';
  if (!article || !article?.unit || Object.keys(article.unit)?.includes(selectedUnit) == false) return null;

  if (article.basicUnitOfMeasure === selectedUnit) return Number(article.numberOfDecimalPlaces);

  return Number(
    article.alternativeUnits?.find?.((unit) => unit.alternate === selectedUnit && unit.alternateType == 1)
      ?.numberOfDecimalPlaces ?? 0
  );
}

const getArticleBaseUnitAmountFunc = function (article, row) {
  return {
    formatted: roundUnit(row.amount * (article.unit?.[row.unit] ?? 1), article.numberOfDecimalPlaces),
    value: roundUnit(row.amount * (article.unit?.[row.unit] ?? 1), article.numberOfDecimalPlaces, false),
  };
};

export function getArticleBaseUnitAmount(article, row) {
  return getArticleBaseUnitAmountFunc(article, row);
}

// Checks if the given list of warehouses are different based on their value/id
export function warehousesHaveChanged(a, b) {
  if (a.length !== b.length) return true;
  const aValues = a.map((w) => w.value);
  const bValues = b.map((w) => w.value);
  return aValues.some((v) => !bValues.includes(v));
}
